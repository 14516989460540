// import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className='App'>
      <h1>
        Welcome to <span style={{ color: "blue" }}>BusinessTank.in</span>
      </h1>
      <p>
        Follow us on Instagram{" "}
        <a
          href='https://www.instagram.com/businesstank.in/'
          target='_blank'
          rel='noreferrer'
          style={{
            textDecoration: "none",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          @businesstank.in
        </a>
      </p>
    </div>
  );
}

export default App;
